.carhartt {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__wrap {
    min-height: 100vh;
    max-height: 100%;
    color: white;
    height: 100%;
    background-color: rgb(207, 211, 216);
  }

  &__img-wrap {
    width: 50%;
    position: relative;
  }

  &__img {
    width: 100%;
  }
}
