.header {
  display: flex;
  flex-direction: column;
  color: rgba($color: #fff, $alpha: 0.5);
  height: 100vh;
  background-color: rgba($color: #000, $alpha: 0.5);
  position: relative;
}

.nav {
  padding-left: 0.8rem;
  display: flex;
  flex-direction: column;
  color: rgba($color: #fff, $alpha: 0.5);

  &__link {
    padding-bottom: 4.8rem;
  }
}

.logo {
  width: 13rem;
  margin-top: -1.6rem;
  margin-left: 3.6rem;
  margin-bottom: 4.8rem;
}

.contact {
  margin-top: 4.8rem;
  margin-left: 0.8rem;
  position: absolute;
  bottom: 2rem;

  &__title {
    margin-bottom: 1.8rem;
  }
  &__text {
    margin-bottom: 0.8rem;
    font-size: 1rem;
    font-weight: bold;
  }

  &__icons {
    display: flex;
    align-items: center;
    margin-left: -1rem;
  }

  &__ig {
    width: 6rem;
    height: 5rem;
  }

  &__yt {
    width: 6rem;
    height: 4rem;
  }
}
