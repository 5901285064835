.dsm {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  &__wrap {
    height: 100%;
    color: white;
    background-color: rgb(207, 211, 216);
  }

  &__img-wrap {
    width: 50%;
    padding-top: 1.6rem;
  }
  &__img {
    width: 100%;
  }
}
