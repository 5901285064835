.always {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  &__wrap {
    max-width: 100%;
    color: white;
    height: 100%;
    background-color: rgb(207, 211, 216);
  }

  &__img-wrap {
    width: 50%;
    // height: 35rem;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__img-wrap-scale {
    height: 50rem;
  }

  &__video {
    width: 100%;
  }
}
