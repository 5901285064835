.management {
  height: 100vh;
  color: white;

  &__main {
    display: flex;
  }

  &__img-wrap {
    width: 50%;
    position: relative;
  }

  &__img {
    width: 100%;
  }

  &__embed {
    margin-top: -0.8rem;
  }
}
