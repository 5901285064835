.container {
  height: 100vh;
  color: white;
}

.events {
  margin-top: 45vh;
  color: white;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  &__link {
    &:hover {
      transform: scale(3);
      transition: 0.2s;
      font-weight: bold;
    }
  }
}
