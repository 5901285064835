.stussy {
  display: flex;
  width: 100%;

  &__wrap {
    height: 100vh;
    color: white;
    height: 100%;
    background-color: rgb(207, 211, 216);
  }

  &__video {
    position: relative;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 1.6rem;
  margin-right: 2.4rem;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
