*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  background-color: rgba($color: red, $alpha: 0.7);
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: rgba($color: #fff, $alpha: 0.5);
  &:hover {
    color: white;
  }
}

.app {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.header {
  width: 25rem;
  padding-left: 0.8rem;
  height: 100vh;
  position: fixed;
  top: 0;
  // background-color: rgba($color: #000, $alpha: 0.4);
}

.main {
  margin-left: 25rem;
  width: 85%;
}

.videoBackground {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}

.img-tag {
  color: rgba($color: blue, $alpha: 0.7);
  font-weight: bold;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  font-size: 1.8rem;
}

.img-tag--lower {
  top: 90px;
}
.img-tag--lower-less {
  top: 50px;
}

.bio-text {
  margin-bottom: 1.6rem;
  line-height: 3.6rem;
  color: yellow;
  font-size: 2.4rem;
}
