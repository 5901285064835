.bonesoda {
  height: 100%;
  &__video {
    width: 100%;
  }

  &__video-wrap {
    position: relative;
  }

  &__wrap {
    color: white;
    height: 100%;
    background-color: rgb(207, 211, 216);
  }

  &__videos {
    display: flex;
    margin-top: 1.6rem;
  }

  &__text {
    margin-top: 1.6rem;
    color: rgba($color: blue, $alpha: 0.5);
  }
}
