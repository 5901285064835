.about {
  color: white;
  width: 100%;
  height: 100vh;

  &__text {
    margin: 5vh 2.4rem 10rem 2.4rem;
    line-height: 3.6rem;
    text-align: center;
    color: rgba($color: #fff, $alpha: 0.7);
  }

  &__logo {
    width: 25rem;
    margin: 0 auto;
  }
  &__img {
    width: 100%;
  }
}

.collaborators {
  width: 80%;
  margin: 0 auto;

  &__title {
    text-align: center;
    margin-bottom: 4.8rem;
  }
  &__cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__card {
    width: 10rem;
    height: 10rem;
    background-color: white;
    box-shadow: 3px 3px 3px rgba($color: #fff, $alpha: 0.7);
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
